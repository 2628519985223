;(function hideNavBar() {
  const toggler = document.querySelector('.navbar-toggler')
  const dropdownItems = document.querySelectorAll('.dropdown-item')
  for (let i = 0; i < dropdownItems.length; i++) {
    const dropdownItem = dropdownItems[i]
    dropdownItem.addEventListener('click', function () {
      toggler.click()
    })
  }
})()


